.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}

.leaflet-control-locate a span {
  line-height: 30px;
}

.leaflet-control-locate.active a {
  color: #2074B6 !important;
}

.leaflet-control-locate.active.following a {
  color: #FC8428 !important;
}

